"use client";

import OtherProviders, {
  ExtendedDoctor,
  LocationForOtherProviders,
} from "@components/OtherProviders";
import {PracticeId} from "src/constants/practiceIds";
import {Pred} from "src/extensions";

type Props = {
  locations: LocationForOtherProviders[];
};

const doctorFilter: Pred<ExtendedDoctor> = d =>
  d.specialties.includes("Primary Care") && d.practiceId === PracticeId.CARBON;

export default function HomeOtherProviders({locations}: Props): JSX.Element {
  return (
    <OtherProviders
      filter={doctorFilter}
      coordinate={{latitude: 39.5501, longitude: -105.7821}}
      radius={2000}
      locations={locations}
    />
  );
}
