import {colors} from "@c10h/colors";
import Insurances from "@components/Insurances";
import React, {useMemo} from "react";
import Hero from "src/components/v5/Home/Hero";
import {ImageGrid} from "src/components/v5/Home/ImageGrid";
import Download from "src/components/v5/Reason/Download";
import {schema} from "src/utils/schemaUtils";

import Layout from "../components/v5/Layout";
import {NavTheme} from "../components/v5/Nav/types";
import Services from "../components/v5/Services";
import TestimonialSlider from "../components/v5/TestimonialSlider";
import {PracticeId} from "../constants/practiceIds";
import {useTypedSelector} from "../store";
import HomeCareDiscoverySearch from "../components/v5/Home/HomeCareDiscoverySearch";
import {localeServerSideProps} from "@i18n/pages";
import {I18nNamespace} from "@i18n";
import {useTranslation} from "@i18n/client";
import {useSortLocations} from "src/hooks/useSortLocations";
import HomeOtherProviders from "@components/Home/HomeOtherProviders";

const Index = () => {
  const i18n = useTranslation();
  const config = useTypedSelector(state => state.config);
  const [locations, locationsSorted] = useSortLocations(config.locations);
  const reasons = useTypedSelector(state => state.config.reasons) ?? [];

  const schemas = [
    {
      "@context": "http://schema.org",
      "@type": "Organization",
      url: schema.website,
      logo: schema.logo,
      contactPoint: [
        {
          "@type": "ContactPoint",
          telephone: schema.address.telephone,
          contactType: "billing support",
          areaServed: "US",
        },
      ],
    },
    {
      "@context": "http://schema.org/",
      "@type": "MedicalOrganization",
      name: "Carbon Health Primary Care",
      url: schema.website,
      address: {
        "@type": "PostalAddress",
        addressCountry: schema.address.addressCountry,
        addressLocality: schema.address.addressLocality,
        addressRegion: schema.address.addressRegion,
        postalCode: schema.address.postalCode,
        streetAddress: schema.address.streetAddress,
        email: schema.address.email,
        telephone: schema.address.telephone,
      },
      logo: {
        "@type": "ImageObject",
        image: schema.logo,
        contentUrl: schema.logo,
      },
    },
    {
      "@context": "http://schema.org",
      "@type": "WebSite",
      name: schema.name,
      headline: schema.title,
      image: schema.logo,
      description: schema.description,
      publisher: {
        "@type": "Organization",
        logo: {
          "@type": "ImageObject",
          url: schema.logo,
        },
      },
    },
  ].map(s => JSON.stringify(s));

  const carbonPractice = config.practices?.findById(PracticeId.CARBON);
  const {downloadDeepLink, slug: practiceSlug} = carbonPractice ?? {};

  const bookingWidget = useMemo(
    () => <HomeCareDiscoverySearch locations={locations} reasons={reasons} />,
    [locations],
  );

  return (
    <Layout
      pageTitle={i18n.t("Carbon Health | Modern Primary & Urgent Care")}
      pageDescription={i18n.t(
        "Carbon Health Provides Smart, hassle-free Primary & Urgent Care. Book same day Adult & Pediatric appointments instantly.",
      )}
      style={{
        "--brandColor": colors.mint,
        "--brandColorDarker": colors.darkerMint,
      }}
      navTheme={NavTheme.LIGHT}
      schemas={schemas}
    >
      <Hero t={i18n.t} bookingWidget={bookingWidget} />
      <ImageGrid t={i18n.t} />
      <Services locations={locations} locationsSorted={locationsSorted} />
      <Download
        trackingLabel="Home"
        locations={locations}
        downloadDeepLink={downloadDeepLink}
        practiceSlug={practiceSlug}
        locationsSorted={locationsSorted}
      />
      <HomeOtherProviders locations={locations} />
      <Insurances t={i18n.t} />
      <TestimonialSlider />
    </Layout>
  );
};

export const getServerSideProps = localeServerSideProps([I18nNamespace.MAIN, I18nNamespace.DB]);

export default Index;
