import {dev} from "../components/_common/_constants";

export type GA = {
  category?: string;
  action?: string;
  label?: string;
};

type GAParams = GA & {
  value?: number;
};

const GA = ({category, action, label, value = 0}: GAParams): boolean | unknown => {
  const wGa = window.ga;
  return !dev && wGa && wGa("send", "event", category, action, label, value);
};
/*
Usage examples:
<div><Btn onClick={() => trackToPatient()}>1</Btn></div>
<div><Btn onClick={() => trackToPatient({ga: {label: "test"}})}>3</Btn></div>
<div><Btn onClick={() => trackToPatient({ga: {label: "test"}})}>4</Btn></div>
<div><Btn onClick={() => trackToPatient({ga: {category: "test", label: "test"}})}>5</Btn></div>
*/

export const trackToPatient = (params?: {ga?: GA}): void => {
  const tatariTrackType = "AddToCart";

  const newGa = {
    category: params?.ga?.category || "Appt Booking CTA",
    action: params?.ga?.action || "CTA Click",
    label: params?.ga?.label || "None",
  };

  if (dev) {
    console.log(`tatari.track("${tatariTrackType}");`);
    if (newGa.label) console.log(`GA(${JSON.stringify(newGa)});`);
  }

  if (typeof tatari !== "undefined") {
    tatari?.track(tatariTrackType);
  }

  if (newGa.label) GA(newGa);
};
