import {useTranslation} from "@i18n/client";
import React from "react";

import {SpecialtyId} from "../../constants/specialtyIds";
import {useGetSoonestInPracticeBySpecialtyId} from "../../hooks/useGetSoonestTime";
import {todayTomorrow4} from "../_common/todayTomorrow";
import {LazyComp} from "../_common/LazyComp";
import {LocationForFetchCachedSlot} from "src/utils/fetchCachedSlotAround";

export const ReadableSlotTimeBySpecialtyId = ({
  specialtyId,
  locations,
  locationsSorted,
}: {
  specialtyId: SpecialtyId;
  locations: LocationForFetchCachedSlot[];
  locationsSorted: boolean;
}): React.ReactElement => {
  const i18n = useTranslation();
  const maybeSlot = useGetSoonestInPracticeBySpecialtyId({
    specialtyId,
    locations,
    locationsSorted,
  });
  const nextApptSlotTime = todayTomorrow4(i18n.t, maybeSlot?.time);

  return <LazyComp>{nextApptSlotTime}</LazyComp>;
};
