import {Btn} from "@components/V5Comp";
import {Trans} from "@i18n";
import React from "react";
import {V5_ROOT} from "src/useV5ImgSrc";
import Image from "next/image";

import {v5Pages} from "../_common/_constants";
import {TranslateFn} from "@i18n";

type Props = {
  t: TranslateFn;
};

const Insurances: React.FC<Props> = ({t}) => {
  return (
    <section
      aria-labelledby="insurance-heading"
      className="pt32 pb120 pv20-sm bgnr bgcov bg-gray100 md:bg-[url(/static/img/v5/insurances/01.webp)]"
      style={{
        backgroundPosition: "80% 50%",
      }}
    >
      <div className="maxw85 ph8 ma">
        <div className="maxw48">
          <h2
            id="insurance-heading"
            className="op1 gray800 mb4 mb2-lg mt0 fs12 lh18 font-ml ls1 ttu"
          >
            {t("Insurance and pricing")}
          </h2>
          <strong className="mb4 fs28-md l maxw30-md font-cm fs40 db maxw40-xl block">
            <Trans t={t}>
              We accept most major insurance plans.{" "}
              <em className="darkBlue fsn">No subscriptions or membership fees.</em>
            </Trans>
          </strong>
          <h4 className="text-lg md:text-2xl pt-6">
            {t("Contact your insurance company to check your coverage.")}
          </h4>
          <Btn
            href={v5Pages.insurance}
            className="event-pUU1qz brd2nc-f pv3-f brd-black-f black-f !text-sm mb8 mt-4"
            bg="transparent"
            aria-label={t("Learn More about insurance and pricing")}
          >
            {t("Learn more")}
          </Btn>
        </div>
      </div>
      <div className="relative w-full aspect-[750/472] md:hidden">
        <Image
          src={`${V5_ROOT}/insurances/01-mobile.webp`}
          alt={t(
            "We accept AARP, United Healthcare, Blue Shield of California and many more. Easily scan your insurance card in our mobile app.",
          )}
          fill
        />
      </div>
    </section>
  );
};

export default Insurances;
