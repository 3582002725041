"use client";

import {ReactNode, useMemo} from "react";
import {useFetchCareEntries} from "../../../queries/useFetchCareEntries";
import {useFeaturedCareSlugs} from "../../../utils/getFeaturedCares";
import {useSendDefaultResultsViewedEvent} from "../Reason/CareDiscoverySearch/careDiscoveryAnalytics";
import CareDiscoverySearch, {
  ReasonForCareDiscoverySearch,
} from "../Reason/CareDiscoverySearch/CareDiscoverySearch";
import {CareEntryRowVariant} from "../CareEntryRow";
import {useTranslation} from "@i18n/client";
import {LocationForRegionSpecialties} from "../../../hooks/useSpecialtiesInRegion";
import {LocationForSoonestSlotForSlug} from "../../../queries/useSoonestSlotByReasonSlug";

export type LocationForHomeCareDiscoverySearch = LocationForRegionSpecialties &
  LocationForSoonestSlotForSlug;

type Props = {
  locations: LocationForHomeCareDiscoverySearch[];
  reasons: ReasonForCareDiscoverySearch[];
};

export default function HomeCareDiscoverySearch({locations, reasons}: Props): ReactNode {
  const {t} = useTranslation();
  const featuredCareSlugs = useFeaturedCareSlugs(locations);
  const defaultResultsQuery = useFetchCareEntries(featuredCareSlugs);

  const viewId = useSendDefaultResultsViewedEvent({
    results: defaultResultsQuery.data,
    isLoading: defaultResultsQuery.isLoading,
    reasons,
  });

  const noResults = !defaultResultsQuery.isLoading && defaultResultsQuery.data.length === 0;
  const loadingRowCount = defaultResultsQuery.isLoading ? featuredCareSlugs.length : undefined;

  const widget = useMemo(
    () => (
      <CareDiscoverySearch
        reasons={reasons}
        locations={locations}
        defaultResults={defaultResultsQuery.data}
        defaultResultsLoadingCount={loadingRowCount}
        dialogTitle={t("Book an Appointment")}
        defaultListRowVariant={CareEntryRowVariant.SM}
        viewId={viewId}
        hideRegionSelector
        hideDefaultListController
        hideDefaultListGradient
        doNotReportDefaultResultsOnMount
        defaultResultsFailed={noResults}
      />
    ),
    [defaultResultsQuery.data, loadingRowCount, t, viewId, noResults],
  );

  return widget;
}
